:root {
  --fontPoppins: "Poppins", sans-serif;
  --accent: #4d47ff;
  --lightGrey: #fafafa;
  --mediumGrey: #9f9f9f;
  margin: 0px;
  padding: 0px;
  font-family: var(--fontPoppins);
  background: linear-gradient(180deg, #2b0e6a 0%, #504dff 100%);
}

@media only screen and (max-width: 600px) {
  * {
    box-sizing: border-box;
  }
}

html,
body {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 36px;
  margin: 0px 0px 24px 0px;
  line-height: 1;
  color: var(--lightGrey);
}

h2 {
  font-size: 16px;
  color: var(--lightGrey);
  line-height: 1;
}

p {
  font-size: 12px;
  margin: 0;
  padding: 0;
  color: var(--lightGrey);
}

a {
  font-size: 16px;
  color: var(--lightGrey);
}

hr {
  border: 0.5px solid rgba(255, 255, 255, 0.294);
  margin-bottom: 12px;
}

.header {
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: space-between;
  height: 10vh;
  padding: 0px 24px 0px 24px;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 99;
}

.logo {
  height: 36px;
  cursor: pointer;
}

.body {
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

@media only screen and (max-width: 700px) {
  html,
  body {
    overflow: auto;
  }
  .body {
    flex-direction: column;
    padding: 24px 0px 0px 0px;
    align-items: start;
    justify-content: start;
    height: 80vh;
  }
  .body.has-video {
    height: auto;
  }
  h1 {
    font-size: 24px;
  }
}

.footer {
  height: auto;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  flex-shrink: 0;
  padding: 32px 24px;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footer .disclaimer {
  text-align: center;
  margin-bottom: 8px;
  width: 80%;
  max-width: 800px;
  line-height: 1.6;
}

.footer-links p,
.footer p {
  font-size: 14px;
  line-height: 1.6;
}

.footer a {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  transition: all 0.3s ease;
  padding: 4px 8px;
  border-radius: 4px;
}

.footer a:hover {
  color: white;
  background: rgba(255, 255, 255, 0.1);
  text-decoration: none;
}

.separator {
  margin: 0 8px;
  color: rgba(255, 255, 255, 0.4);
}

@media only screen and (max-width: 700px) {
  .footer {
    padding: 24px 16px;
  }

  .footer .disclaimer {
    width: 100%;
  }

  .footer-links p {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 8px;
  }

  .footer a {
    margin: 4px 0;
  }

  .separator {
    display: none;
  }
}

.settings-container {
  display: block;
}

.setting-button {
  background-color: #ffffff00;
  color: var(--lightGrey);
  border-radius: 50px;
  border: solid;
  display: inline-block;
  cursor: pointer;
  font-size: 16px;
  padding: 8px 16px;
  text-decoration: none;
  margin-bottom: 6px;
  margin-right: 6px;
  border: 1px solid rgba(255, 255, 255, 0.294);
}

.setting-button:hover {
  background-color: var(--lightGrey);
  color: var(--accent);

  box-shadow: 0px 0px 20px -5px #ffffff;
}

.setting-button-selected {
  background-color: var(--lightGrey);
  color: var(--accent);
  box-shadow: 0px 0px 20px -5px #ffffff;
}
.setting-category {
  margin-bottom: 12px;
}

.setting-hint {
  margin-bottom: 24px;
  font-size: 16px;
}

.setting-hint,
p {
  color: rgba(255, 255, 255, 0.588);
}

.information {
  width: 33%;
  margin-right: 48px;
  padding-right: 24px;
}

@media only screen and (max-width: 700px) {
  .information {
    width: auto;
    margin: 0;
    padding: 0px 24px 0px 24px;
  }

  .footer {
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
  }
}

.list {
  list-style: none inside;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  padding: 24px 0 0 0;
}

.list li {
  padding-bottom: 14px;
  color: var(--lightGrey);
}

@media only screen and (max-width: 700px) {
  .list {
    padding: 24px 0 12px 0;
  }
  .list li {
    padding-bottom: 12px;
  }
}

.dropzone {
  background-color: #ffffff00;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20%;
  aspect-ratio: 9 / 16;
  border: 2px solid #8387ff;
  border-radius: 15px;
  text-align: center;
  cursor: pointer;
  overflow: hidden;
  box-shadow: 0px 0px 50px -20px #a9aaff;
}

.dropzone.has-video {
  aspect-ratio: 9 / 16;
  background-color: black;
}

@media only screen and (max-width: 1000px) {
  .dropzone {
    width: 25%;
  }
}
@media only screen and (max-width: 700px) {
  .dropzone {
    width: calc(100% - 48px);
    margin-bottom: 24px;
    margin-left: 24px;
    height: 100%;
    aspect-ratio: auto;
  }

  .dropzone.has-video {
    aspect-ratio: 9 / 16;
    background-color: black;
  }
}

.upload-button {
  background-color: #ffffff00;
  color: white;
  border-radius: 5px;
  border: none;
  display: inline-block;
  cursor: pointer;
  font-size: 12px;
  padding: 6px 12px;
  text-decoration: none;
  margin-bottom: 6px;
  border: 1px solid rgba(255, 255, 255, 0.294);
}

.upload-button:hover {
  background-color: var(--lightGrey);
  color: var(--accent);

  box-shadow: 0px 0px 20px -5px #ffffff;
}

.hint {
  font-size: 12px;
  color: var(--lightGrey);
  cursor: pointer;
}

.video {
  display: block;
  width: 100%;
  border-radius: 0px;
  cursor: pointer;
}

.uploaded-image {
  display: block;
  width: 100%;
  border-radius: 0px;
  cursor: pointer;
}

.overlay {
  position: absolute;
  width: 100%;
  border-radius: 0px;
  z-index: 1;
}

.knowledge-base {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 64px 48px 128px 48px;
  background-color: var(--accent);
  color: white;
}

@media only screen and (max-width: 1000px) {
  .knowledge-base {
    padding: 64px 24px 128px 24px;
  }
}
/* Loading Bar */

.loading-bar-container {
  width: 60%;
  height: 12px;
  border-style: solid;
  border-width: 1px;
  border-radius: 6px;
  border-color: var(--lightGrey);
}

.loading-bar-filling {
  height: 100%;
  width: 0%;
  background-color: var(--lightGrey);
  border-radius: 4px;
  box-shadow: 0px 0px 20px -5px #ffffff;
}

@keyframes fillBar {
  to {
    width: 100%;
  }
}

.youtube-icon {
  position: absolute;
  top: 50%; /* Beispielposition für das YouTube-Icon */
  left: 57%; /* Beispielposition */
  width: 80px;
  z-index: 2;
}

.instagram-icon {
  position: absolute;
  top: 25%; /* Beispielposition für das Instagram-Icon */
  right: 25%; /* Beispielposition */
  width: 80px;
  z-index: 2;
}

.tiktok-icon {
  position: absolute;
  bottom: 25%; /* Beispielposition für das TikTok-Icon */
  left: 75%; /* Beispielposition */
  width: 80px;
  z-index: 2;
}

/* Media Query für Geräte mit einer maximalen Breite von 600px */
@media only screen and (max-width: 600px) {
  .youtube-icon {
    top: 75%; /* Neue Position für YouTube-Icon */
    left: 20%; /* Neue Position */
    width: 60px; /* Neue Größe */
    transform: translate(-50%, -50%); /* Zentrieren des Icons */
  }

  .instagram-icon {
    top: 65%; /* Neue Position für Instagram-Icon */
    right: 10%; /* Neue Position */
    width: 60px; /* Neue Größe */
    transform: translate(-50%, -50%); /* Zentrieren des Icons */
  }

  .tiktok-icon {
    bottom: 5%; /* Neue Position für TikTok-Icon */
    left: 80%; /* Neue Position */
    width: 60px; /* Neue Größe */
    transform: translate(-50%, -50%); /* Zentrieren des Icons */
  }
}

@keyframes scaleOut {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(0);
    opacity: 0;
  }
}

.hide-icon {
  animation: scaleOut 0.5s ease forwards;
}

.cookie-banner {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background: #2339ca;
  color: var(--lightGrey); /* Use light grey for the text */
  border: 1px solid rgba(255, 255, 255, 0.294);
  border-radius: 15px;
  padding: 20px;
  text-align: center;
  font-family: var(--fontPoppins); /* Match the font style */
  z-index: 999; /* Ensure the banner is always on top */
  max-width: 400px;
  width: 90%; /* Make the banner responsive */
  margin: 0 auto;
}

.cookie-banner p {
  margin: 0;
  padding: 0 0 10px 0;
  font-size: 12px; /* Match the body font size */
  color: var(--lightGrey); /* Consistent color */
}

.cookie-link {
  color: var(--lightGrey);
  text-decoration: underline;
  font-size: 12px;
}

.cookie-link:hover {
  color: white;
}

.cookie-banner button {
  margin: 0 2px;
  padding: 6px 12px;
  background-color: var(--lightGrey); /* Use light grey for buttons */
  color: #2323ca;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: var(--fontPoppins); /* Match the font */
  font-size: 12px;
  border: 1px solid rgba(255, 255, 255, 0.294);
}

.cookie-banner button:hover {
  background-color: #2323ca;
  color: white;
  box-shadow: 0px 0px 20px -5px #ffffff;
  border: 1px solid rgba(255, 255, 255, 0.294);
}

.reopen-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: #2323ca;
  color: var(--lightGrey);
  border-radius: 50%;
  border: none;
  cursor: pointer;
  font-size: 24px;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 20px -5px #ffffff;
}

.reopen-button:hover {
  background-color: var(--lightGrey);
  color: #684dff;
}

.policy-container {
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  font-family: var(--fontPoppins);
  color: var(--lightGrey);
}

.policy-container h1 {
  font-size: 36px;
  color: var(--lightGrey);
}

.policy-container h2 {
  font-size: 24px;
  margin-top: 20px;
  color: var(--lightGrey);
}

.policy-container p,
.policy-container ul {
  font-size: 16px;
  line-height: 1.6;
  color: var(--mediumGrey);
}

.policy-container ul {
  padding-left: 20px;
}

.policy-container a {
  color: var(--lightGrey);
  text-decoration: underline;
}

.policy-container a:hover {
  color: white;
}

.laria-animation {
  width: 100%;
  max-width: 400px;
  margin-top: 24px;
  border-radius: 15px;
  box-shadow: 0px 0px 50px -20px #a9aaff;
  margin-bottom: 24px;
}
